export const tableOption = {
  searchMenuSpan: 6,
  columnBtn: false,
  border: true,
  // selection: true,
  index: false,
  indexLabel: '序号',
  stripe: true,
  menuAlign: 'center',
  menuWidth: 350,
  align: 'center',
  refreshBtn: true,
  searchSize: 'mini',
  addBtn: false,
  editBtn: false,
  delBtn: false,
  viewBtn: false,
  props: {
    label: 'label',
    value: 'value'
  },
  column: [{
    label: '用户类型',
    prop: 'userType',
    slot: true
  }, {
    label: '公司',
    prop: 'orgName',
    search: true
  }, {
    label: '用户ID',
    prop: 'userId'
  }, {
    label: '用户昵称',
    prop: 'nickName',
    search: true
  }, {
    label: '用户姓名',
    prop: 'userName',
    search: true
  }, {
    label: '手机号',
    prop: 'userMobile',
    search: true
  }, {
    label: '用户邮箱',
    prop: 'userEmail'
  }, {
    label: '微信号ID',
    prop: 'weixin',
    search: true
  }, {
    label: '用户头像',
    prop: 'pic',
    type: 'upload',
    imgWidth: 150,
    listType: 'picture-img'
  }, {
    label: '性别',
    prop: 'sex'
  }, {
    label: '状态',
    prop: 'status',
    search: true,
    type: 'select',
    slot: true,
    dicData: [
      {
        label: '禁用',
        value: 0
      }, {
        label: '正常',
        value: 1
      }
    ]
  }, {
    label: '注册时间',
    prop: 'userRegtime',
    imgWidth: 150
  }]
}
